<template>
  <div class="examine vertical-center">
    <img class="examine-img" src="~assets/images/index/reject-bg.png" alt="">
    <div class="examine-body vertical-center">
      <div class="examine-state">审核中</div>
      <div class="examine-text flex text-justify-t">您的申请已提交，请耐心等待~</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Audit",
  data(){
    return{

    }
  },
  created() {
    if(this.userInfo && this.userInfo.status_team){
      // 判断有战队时进入首页
      if(this.userInfo.status_team.operate_status == 3){
        this.$router.replace('/')
      }else if(this.userInfo.status_team.operate_status == 4){
        this.$router.replace('/join')
      }
    }else {
      this.$router.replace('/join')
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
}
</script>

<style lang="scss" scoped >
@import "~assets/scss/user/audit/examine.scss";
</style>
